import React from 'react';
import {
    Box,
    ResponsiveContext,
} from "grommet";
import Layout from '../components/elements/layout';
import Header from '../components/header'
import MainSeparator from '../components/elements/separator/main_separator';
import Review, { LoadReview } from '../components/elements/review';
import MainMenu from '../components/menu';
import salon from '../config/data.json';
import { getSalon } from '../services/service';

const seo = {
    title: 'BE PERFECT MANUCURE | Avis Des Clients',
    description: "Les retours d'expérience des nos aimables clients. Ici, chez BE PERFECT MANUCURE, votre satisfaction est notre priorité."
}

class Avis extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.salon || {
            salon
        }
    }

    componentDidMount = () => {
        getSalon()
            .then(res => {
                this.setState({ salon: res.data })
            })
    }

    render() {
        return (
            <Layout style={{ justifyContent: "center", alignContent: "center" }}
                {...seo}
            >
                <Box width="xlarge" justify="center" align="center" alignSelf="center">
                    <ResponsiveContext.Consumer>
                        {size =>
                            <Box width="xlarge" pad="medium">
                                <Header data={this.state.salon.data} size={size} />
                                <MainMenu />
                                <Box style={{height: 50}}/>
                                <Review data={this.state.salon.data} size={size} />
                                <MainSeparator />
                                <LoadReview reviews={this.state.salon.reviews.reviews} size={size} pageSize={100} />

                            </Box>
                        }
                    </ResponsiveContext.Consumer>

                    <Box style={{ height: 200 }} />
                </Box>
            </Layout>
        )
    }
}

export default Avis;