import { Box, Text } from 'grommet';
import Separator from '../separator';
import React from 'react';

import TimeAgo from 'react-timeago';
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(frenchStrings)

const Review = ({ reviews, data, size }) => {
    return (
        <Box direction={size === 'small' ? 'column' : 'row'} gap="large">
            <Box direction='row' style={{ width: size ==='small' ? '100%' : '30%' }} gap="small"  justify="between">
                <Box justify="center">
                    <span style={{ fontSize: 68, color: '#ffb500' }}>{data.rating?.displayAverage}</span>
                </Box>
                <Box justify="center" gap="small">
                    <Box direction='row' gap="small">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    </Box>
                    <Text style={{ color: '#bebebe' }} textAlign='end'>{data.rating?.count || 23} avis</Text>
                </Box>
            </Box>
            <Box gap="small" style={{ width: size ==='small' ? '100%' : '30%' }}>
                <Box gap="small" direction='row' justify="between">
                    <Text style={{ color: '#4d4d4d' }}>Ambiance</Text>
                    <Box direction='row' gap='xsmall'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    </Box>
                </Box>
                <Separator />
                <Box gap="small" direction='row' justify="between">
                    <Text style={{ color: '#4d4d4d' }}>Propreté</Text>
                    <Box direction='row' gap='xsmall'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    </Box>
                </Box>
            </Box>
            <Box style={{ width: size ==='small' ? '100%' : '30%' }}>
                <Box gap="small" direction='row' justify="between">
                    <Text style={{ color: '#4d4d4d' }}>Personnel</Text>
                    <Box direction='row' gap='xsmall'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


const IndividualReview = ({ reviewer, content, rating, treatmentNames, employeeDescription, loadMore, onload, size, createdAt }) => {
    return (
        <Box gap="small" style={{ marginTop: 15 }}>
            <Box direction='row' gap='small'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                {rating === 5 && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>}
            </Box>
            <Box>
                <Text size="small" style={{ color: '#4d4d4d' }}>
                    {content?.content}
                </Text>
            </Box>
            <Box direction={'row'} gap="small" >
                <Box justify="center" align="center"><Text style={{ color: '#bebebe' }} size="xsmall">{employeeDescription}</Text></Box> •

                {
                    treatmentNames?.map((des, index) => (
                        <Box round={{ size: 'large' }} border={'all'} justify="center" align="center" key={index}>
                            <Box justify="center" key={index} align="center" style={{ paddingLeft: 5, paddingRight: 5, width: '100%' }}>
                                <Text size="xsmall" textAlign='center'>{des?.replace(/\n/g, ' ')}</Text>
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            <Box style={{ width: 20, border: '1px solid #e8e8e8', height: 2 }} />
            <Box direction='row' gap="small" >
                <Box justify="center" align="center"><Text size="xsmall">{reviewer?.name || 'Anonyme'}</Text></Box>
                <Box justify="center" align="center"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><defs><path id="a" d="M16.138 7.956l1.542 1.543-7.316 7.317L6.32 12.77l1.542-1.542 2.502 2.5 5.774-5.773zM12 21.818c5.422 0 9.818-4.396 9.818-9.818S17.422 2.182 12 2.182 2.182 6.578 2.182 12 6.578 21.818 12 21.818zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"></path></defs><use fill="#00B0B9" fill-rule="evenodd" href="#a"></use></svg></Box>
                •
                <Box justify="center" align="center" style={{ paddingLeft: 5, paddingRight: 5 }}>
                    <Text style={{ color: '#bebebe' }} size="xsmall"><TimeAgo date={createdAt} formatter={formatter} /></Text>
                </Box>
            </Box>
            {loadMore ? <Text size={size === 'small' ? 'small' : 'medium'} style={{ cursor: 'pointer', color: '#001e62' }} onClick={onload}>Voir plus d'avis...</Text>: <Separator />}
        </Box>
    )
}

const LoadReview = ({ reviews, size, pageSize=5 }) => {

    const [page, setPage] = React.useState(1);

    const onload = () => {
        if (page * pageSize >= reviews.length) return;
        setPage(page + 1);
    }

    return (
        reviews.slice(0, page * pageSize).map((r, index) => (
            <IndividualReview key={index} {...r} size={size} loadMore={page * pageSize - 1 === index} onload={onload} />
        ))
    )
}

export {
    IndividualReview,
    LoadReview
}
export default Review;